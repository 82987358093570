<template>
  <div>
    <modal name="modal-add-message" width="90%" height="auto">
      <div class="p-5" style="z-index: 999 !important;">
        <div class="flex items-center border-b pb-4 mb-2 justify-between">
          <h2 class="text-xl font-bold">Laissez un message</h2>
          <button class="cursor-pointer" @click="$modal.hide('modal-add-message')">
            <i class="fas fa-times text-red-500 text-xl"></i>
          </button>
        </div>
        <div>
          <div class="mb-5">
            <label for="name" class="block mb-2 text-sm font-medium text-gray-900">Votre prénom (optionnel)</label>
            <input v-model="form.nom" type="name" id="name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
          </div>
          <div class="mb-5">
            <label for="message" class="block mb-2 text-sm font-medium text-gray-900">Message</label>
            <textarea v-model="form.message" type="message" id="message" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" rows="5"></textarea>
          </div>
          <div class="text-center">
            <button @click="addMessage" class="px-6 py-3 bg-green-200 rounded-full">Ajouter <i class="fas fa-plus ml-1"></i></button>
          </div>
        </div>
      </div>
    </modal>

    <div v-if="place" class="px-4">
      <div class="flex items-center gap-4 py-6 border-b">
        <img class="h-[50px]" src="https://i.ibb.co/t83fh9b/location-icon-logo-illustration-map-symbol-template-for-graphic-and-web-design-collection-free-vecto.png" alt="">
        <div>
          <div class="font-bold text-xl">Nos Msg</div>
          <div class="font-light text-sm">
            Laissez un message sur une étiquette que vous avez trouvé
          </div>
        </div>
      </div>
      <h2 class="font-light text-xl mt-6">La dernière personne qui a scanné cette etiquette a laissé un message :</h2>
      <div class="bg-slate-100 border rounded p-4 mt-4">
        <div class="text-2xl">{{ place.message.message }}</div>
        <div class="border-t border-slate-600 pt-2 mt-4">
          <div class="text-sm font-light" v-if="place.message.nom">Message de <b>{{ place.message.nom }}</b></div>
          <div class="text-xs font-light mt-1">Ajouté le {{ place.message | momentDate }}</div>
        </div>
      </div>
      <div class="text-center">
        <button @click="$modal.show('modal-add-message')" class="px-10 py-3 bg-green-200 rounded-full mt-6"> 
          <div class="font-bold text-lg">Ajouter un message</div>
          <div class="text-sm font-light">pour la prochaine personne</div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
export default {
  data() {
    return {
      place: null,
      form: {}
    }
  },
  filters: {
    momentDate(date) {
      if (date) {
        return moment(date).format('DD/MM/YYYY')
      }
      return '-'
    }
  },
  methods: {
    async getPlace() {
      // let response = await axios.get('http://localhost:8091/api/place/' + this.$route.params.token)
      let response = await axios.get('https://api.nos-msg.com/api/place/' + this.$route.params.token)
      this.place = response.data
    },
    async addMessage() {
      this.form.token = this.$route.params.token
      // let response = await axios.post('http://localhost:8091/api/message/add', this.form)
      let response = await axios.post('https://api.nos-msg.com/api/message/add', this.form)
      if (response.data == 1) {
        await this.getPlace()
        this.form = {}
        this.$modal.hide('modal-add-message')
        this.$router.push({name: 'valid-message'})
      }
    }
  },
  mounted () {
    this.getPlace();
  },
}
</script>

<style lang="scss" scoped>

</style>